import { Box, Button, Stack } from '@mui/material';
import useSearchBar from 'src/hooks/useSearchBar';
import { useRouter } from 'src/hooks/useRouter';
import BusinessQuoteTable from './BusinessQuoteTable';
import BusinessQuoteForm from './BusinessQuoteForm';

function BusinessQuoteMaintenance() {
  const { route, navigate } = useRouter();

  const [searchBar, { filterValues }] = useSearchBar();

  return (
    <>
      {route.id === undefined && (
        <Box>
          <Stack gap={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              {searchBar}
              <Button
                sx={{ flexShrink: 0 }}
                variant="contained"
                onClick={() => navigate({ id: -1 })}
                aria-label="add-business-quote"
              >
                新增報價單
              </Button>
            </Stack>
            <BusinessQuoteTable
              onSelected={(businessQuote) => {
                if (businessQuote) navigate({ id: businessQuote.id });
              }}
              filterValues={filterValues}
            />
          </Stack>
        </Box>
      )}
      {route.id !== undefined && (
        <BusinessQuoteForm
          businessQuoteId={route.id === -1 ? undefined : route.id}
          onRequestClose={() => navigate({ id: undefined })}
        />
      )}
    </>
  );
}

export default BusinessQuoteMaintenance;
