import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Dropdown from 'src/components/shared/Dropdown';
import StringField from 'src/components/shared/StringField';
import {
  useFactoryQuoteTagForCustomerListQuery,
  useFactoryQuoteTagGroupListQuery,
  useModelTypeListQuery,
} from 'src/hooks';
import type { FactoryQuoteModel, FactoryQuoteTagGroup, ModelType } from 'src/models/factoryQuote';
import {
  FACTORY_QUOTE_MODEL_FIELD_TO_NAME,
  FACTORY_QUOTE_MODEL_MOLD_LOCATION_OPTIONS,
  FACTORY_QUOTE_MODEL_ASSEMBLY_OPTIONS,
  FACTORY_QUOTE_TAG_GROUP_TYPES,
} from './constants';
import TransferList from '../shared/TransferList';
import { FACTORY_QUOTE_TAG_FIELD_TO_NAME } from '../TagMaintenance/constants';
import FilterableDropdown from '../shared/FilterableDropdown';

const DIRECT_TAG_COLUMNS = [
  { field: 'groupName', headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME.groupName, width: 100 },
  { field: 'name', headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME.name, width: 100 },
  { field: 'cost', headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME.cost, width: 100 },
];

function FactoryQuoteModelEditDialog({
  customerId,
  factoryQuoteModel: originalFactoryQuoteModel,
  onRequestClose,
  onSubmitted,
}: {
  customerId: number;
  factoryQuoteModel?: FactoryQuoteModel;
  onRequestClose: () => void;
  onSubmitted: (factoryQuoteModel: FactoryQuoteModel) => void;
}) {
  const { modelTypeList } = useModelTypeListQuery();
  const { factoryQuoteTagGroupList, isFactoryQuoteTagGroupListLoading } = useFactoryQuoteTagGroupListQuery();

  const modelTypeOptions = useMemo(
    () => modelTypeList.map(({ name }) => ({ value: name, label: name })),
    [modelTypeList],
  );

  const [selectedModelTypeOption, setSelectedModelTypeOption] = useState<{
    label: ModelType['name'];
    value: ModelType['name'];
  } | null>(null);

  useEffect(() => {
    if (!originalFactoryQuoteModel) return;
    setSelectedModelTypeOption(
      modelTypeOptions.find(({ value }) => value === originalFactoryQuoteModel.modelType) ?? null,
    );
  }, [modelTypeOptions, originalFactoryQuoteModel]);

  const factoryQuoteTagGroupIdMap = useMemo(
    () =>
      factoryQuoteTagGroupList.reduce<Record<number, FactoryQuoteTagGroup>>(
        (result, factoryQuoteTagGroup) => ({ ...result, [factoryQuoteTagGroup.id]: factoryQuoteTagGroup }),
        {},
      ),
    [factoryQuoteTagGroupList],
  );

  const { factoryQuoteTagForCustomerList, isFactoryQuoteTagForCustomerListLoading } =
    useFactoryQuoteTagForCustomerListQuery({
      customerId,
      modelType: selectedModelTypeOption?.value,
      types: FACTORY_QUOTE_TAG_GROUP_TYPES,
    });

  const factoryQuoteTagForCustomerItems = useMemo(
    () =>
      factoryQuoteTagForCustomerList
        .filter(({ type }) => type === 'direct')
        .map(({ id, name, group, cost }) => ({
          id,
          groupName: factoryQuoteTagGroupIdMap[group]?.name ?? '',
          name,
          cost,
        })),
    [factoryQuoteTagForCustomerList, factoryQuoteTagGroupIdMap],
  );

  const [selectedFactoryQuoteTagForCustomerItems, setSelectedFactoryQuoteTagForCustomerItems] = useState<
    (typeof factoryQuoteTagForCustomerItems)[number][]
  >([]);

  useEffect(() => {
    if (!originalFactoryQuoteModel) return;
    setSelectedFactoryQuoteTagForCustomerItems(
      originalFactoryQuoteModel.direct.flatMap(
        ({ id }) => factoryQuoteTagForCustomerItems.find((item) => item.id === id) ?? [],
      ),
    );
  }, [factoryQuoteTagForCustomerItems, originalFactoryQuoteModel]);

  const [modelName, setModelName] = useState<FactoryQuoteModel['modelName'] | undefined>(
    originalFactoryQuoteModel?.modelName,
  );

  const [moldQuoteLocation, setMoldQuoteLocation] = useState<
    FactoryQuoteModel['moldQuoteLocation'] | undefined
  >(originalFactoryQuoteModel?.moldQuoteLocation);

  const [assembly, setAssembly] = useState<FactoryQuoteModel['assembly'] | undefined>(
    originalFactoryQuoteModel?.assembly,
  );

  const [moldLocation, setMoldLocation] = useState<FactoryQuoteModel['moldLocation'] | undefined>(
    originalFactoryQuoteModel?.moldLocation,
  );

  const onSubmit = useMemo(() => {
    if (
      moldQuoteLocation !== undefined &&
      assembly !== undefined &&
      selectedModelTypeOption !== null &&
      modelName !== undefined &&
      moldLocation !== undefined
    ) {
      return () =>
        onSubmitted({
          moldQuoteLocation,
          assembly,
          modelType: selectedModelTypeOption.value,
          modelName,
          moldLocation,
          direct: selectedFactoryQuoteTagForCustomerItems.map(({ id, cost }) => ({ id, cost })),
          indirect: factoryQuoteTagForCustomerList
            .filter(({ type }) => type === 'indirect')
            .map(({ id, cost }) => ({ id, cost })),
        });
    }

    return undefined;
  }, [
    assembly,
    factoryQuoteTagForCustomerList,
    modelName,
    moldLocation,
    moldQuoteLocation,
    onSubmitted,
    selectedFactoryQuoteTagForCustomerItems,
    selectedModelTypeOption,
  ]);

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>{`${originalFactoryQuoteModel ? '編輯' : '新增'}形體`}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FilterableDropdown
            label={FACTORY_QUOTE_MODEL_FIELD_TO_NAME.modelType}
            options={modelTypeOptions}
            value={selectedModelTypeOption}
            onChange={(newValue) => {
              setSelectedModelTypeOption(newValue);
              setSelectedFactoryQuoteTagForCustomerItems([]);
            }}
            disabled={!!originalFactoryQuoteModel}
          />
          <StringField
            label={FACTORY_QUOTE_MODEL_FIELD_TO_NAME.modelName}
            value={modelName}
            onChange={setModelName}
            disabled={!selectedModelTypeOption}
          />
          <Dropdown
            label={FACTORY_QUOTE_MODEL_FIELD_TO_NAME.moldQuoteLocation}
            options={FACTORY_QUOTE_MODEL_MOLD_LOCATION_OPTIONS}
            value={moldQuoteLocation}
            onChange={setMoldQuoteLocation}
            disabled={!selectedModelTypeOption}
          />
          <Dropdown
            label={FACTORY_QUOTE_MODEL_FIELD_TO_NAME.assembly}
            options={FACTORY_QUOTE_MODEL_ASSEMBLY_OPTIONS}
            value={assembly}
            onChange={setAssembly}
            disabled={!selectedModelTypeOption}
          />
          <Dropdown
            label={FACTORY_QUOTE_MODEL_FIELD_TO_NAME.moldLocation}
            options={FACTORY_QUOTE_MODEL_MOLD_LOCATION_OPTIONS}
            value={moldLocation}
            onChange={setMoldLocation}
            disabled={!selectedModelTypeOption}
          />
          {selectedModelTypeOption && (
            <TransferList
              title="直接費用選項"
              items={factoryQuoteTagForCustomerItems}
              selectedItems={selectedFactoryQuoteTagForCustomerItems}
              onChange={setSelectedFactoryQuoteTagForCustomerItems}
              columns={DIRECT_TAG_COLUMNS}
              isLoading={isFactoryQuoteTagForCustomerListLoading || isFactoryQuoteTagGroupListLoading}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} variant="outlined">
          取消
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit}>
          {originalFactoryQuoteModel ? '更新' : '新增'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FactoryQuoteModelEditDialog;
