import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';
import useSearchBar from 'src/hooks/useSearchBar';
import type { Customer } from 'src/models/customer';
import CustomerTable from './CustomerTable';
import CustomerEditDialog from './CustomerEditDialog';

function CustomerMaintenance() {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [searchBar, { filterValues }] = useSearchBar();

  return (
    <>
      <Box>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            {searchBar}
            <Button
              sx={{ flexShrink: 0 }}
              variant="contained"
              onClick={() => setIsEditDialogOpen(true)}
              aria-label="add-customer"
            >
              新增客戶
            </Button>
          </Stack>
          <CustomerTable
            onSelected={(customer) => {
              setSelectedCustomer(customer);
              setIsEditDialogOpen(true);
            }}
            filterValues={filterValues}
          />
        </Stack>
      </Box>
      {isEditDialogOpen && (
        <CustomerEditDialog
          customer={selectedCustomer}
          onRequestClose={() => {
            setIsEditDialogOpen(false);
            setSelectedCustomer(undefined);
          }}
        />
      )}
    </>
  );
}

export default CustomerMaintenance;
