import type {
  Sample,
  SampleSaveFormat,
  SampleTag,
  SampleTagGroup,
  SampleTagGroupSaveFormat,
  SampleTagSaveFormat,
} from 'src/models/sample';
import type { Customer, CustomerSaveFormat } from 'src/models/customer';
import type {
  FactoryQuote,
  FactoryQuoteDetail,
  FactoryQuoteDetailSaveFormat,
  FactoryQuoteHistory,
  FactoryQuoteItem,
  FactoryQuoteTag,
  FactoryQuoteTagForCustomer,
  FactoryQuoteTagGroup,
  FactoryQuoteTagGroupSaveFormat,
  FactoryQuoteTagSaveFormat,
  ModelType,
} from 'src/models/factoryQuote';
import type {
  BusinessQuote,
  BusinessQuoteDetail,
  BusinessQuoteDetailSaveFormat,
} from 'src/models/businessQuote';
import { createAddFunction, createGetFunction, createGetListFunction, createUpdateFunction } from './apiBase';

export const getSampleTagGroupList = createGetListFunction<SampleTagGroup>('sampleTagGroup', 'tagFieldGroup');

export const getSampleTagList = createGetListFunction<SampleTag>('sampleTag', 'tagField');

export const getSampleList = createGetListFunction<Sample>('sample', 'product');

export const getCustomerList = createGetListFunction<Customer>('customer');

export const getFactoryQuoteTagGroupList =
  createGetListFunction<FactoryQuoteTagGroup>('factoryQuoteTagGroup');

export const getFactoryQuoteTagList = createGetListFunction<FactoryQuoteTag>('factoryQuoteTag');

export const getFactoryQuoteTagForCustomerList = createGetListFunction<FactoryQuoteTagForCustomer>(
  'factoryQuoteTagForCustomer',
);

export const getFactoryQuoteList = createGetListFunction<FactoryQuote>('factoryQuote');

export const getFactoryQuoteItemList = createGetListFunction<FactoryQuoteItem>('factoryQuoteItem');

export const getFactoryQuoteHistoryList = createGetListFunction<FactoryQuoteHistory>('factoryQuoteHistory');

export const getFactoryQuote = createGetFunction<FactoryQuoteDetail>('factoryQuote');

export const getModelTypeList = createGetListFunction<ModelType>('modelType');

export const getBusinessQuoteList = createGetListFunction<BusinessQuote>('businessQuote');

export const getBusinessQuote = createGetFunction<BusinessQuoteDetail>('businessQuote');

export const addSampleTagGroup = createAddFunction<SampleTagGroupSaveFormat, SampleTagGroup>(
  'sampleTagGroup',
  'tagFieldGroup',
);

export const addSampleTag = createAddFunction<SampleTagSaveFormat, SampleTag>('sampleTag', 'tagField');

export const addSample = createAddFunction<SampleSaveFormat>('sample', 'product');

export const addCustomer = createAddFunction<CustomerSaveFormat, Customer>('customer');

export const addFactoryQuoteTagGroup = createAddFunction<
  FactoryQuoteTagGroupSaveFormat,
  FactoryQuoteTagGroup
>('factoryQuoteTagGroup');

export const addFactoryQuoteTag = createAddFunction<FactoryQuoteTagSaveFormat, FactoryQuoteTag>(
  'factoryQuoteTag',
);

export const addFactoryQuote = createAddFunction<FactoryQuoteDetailSaveFormat, FactoryQuoteDetail>(
  'factoryQuote',
);

export const addBusinessQuote = createAddFunction<BusinessQuoteDetailSaveFormat, BusinessQuoteDetail>(
  'businessQuote',
);

export const updateSampleTagGroup = createUpdateFunction<SampleTagGroup>('sampleTagGroup', 'tagFieldGroup');

export const updateSampleTag = createUpdateFunction<SampleTag>('sampleTag', 'tagField');

export const updateSample = createUpdateFunction<Sample>('sample', 'product');

export const updateCustomer = createUpdateFunction<Customer>('customer');

export const updateFactoryQuoteTagGroup = createUpdateFunction<FactoryQuoteTagGroup>('factoryQuoteTagGroup');

export const updateFactoryQuoteTag = createUpdateFunction<FactoryQuoteTag>('factoryQuoteTag');

const updateFactoryQuoteBase = createUpdateFunction<FactoryQuoteDetail>('factoryQuote');
export const updateFactoryQuote = (payload: FactoryQuoteDetail) =>
  updateFactoryQuoteBase(payload, { method: 'PUT' });

const updateBusinessQuoteBase = createUpdateFunction<BusinessQuoteDetail>('businessQuote');
export const updateBusinessQuote = (payload: BusinessQuoteDetail) =>
  updateBusinessQuoteBase(payload, { method: 'PUT' });
