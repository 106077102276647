import { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import useSearchBar from 'src/hooks/useSearchBar';
import type { SampleTag } from 'src/models/sample';
import SampleTagTable from './SampleTagTable';
import SampleTagEditDialog from './SampleTagEditDialog';

function SampleTagMaintenance() {
  const [selectedTag, setSelectedTag] = useState<SampleTag | undefined>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [searchBar, { filterValues }] = useSearchBar();

  return (
    <>
      <Box marginTop={2}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            {searchBar}
            <Button
              sx={{ flexShrink: 0 }}
              variant="contained"
              onClick={() => setIsEditDialogOpen(true)}
              aria-label="add-sample-tag"
            >
              新增樣品屬性
            </Button>
          </Stack>

          <SampleTagTable
            onSelected={(tag) => {
              setSelectedTag(tag);
              setIsEditDialogOpen(true);
            }}
            filterValues={filterValues}
          />
        </Stack>
      </Box>
      {isEditDialogOpen && (
        <SampleTagEditDialog
          tag={selectedTag}
          onRequestClose={() => {
            setIsEditDialogOpen(false);
            setSelectedTag(undefined);
          }}
        />
      )}
    </>
  );
}

export default SampleTagMaintenance;
