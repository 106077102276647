import type { PropsWithChildren } from 'react';
import { useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useSampleTagGroupListQuery, useSampleListQuery, useSampleTagListQuery } from 'src/hooks';
import { useMutation } from '@tanstack/react-query';
import { addSample, updateSample } from 'src/apis';
import StringField from 'src/components/shared/StringField';
import type { Sample, SampleSaveFormat, SampleTag, SampleTagValue } from 'src/models/sample';
import BooleanField from 'src/components/shared/BooleanField';
import NumberField from 'src/components/shared/NumberField';
import ImageField from '../shared/ImageField';
import ErrorMessage from '../shared/ErrorMessage';

function FieldWrapper({ children }: PropsWithChildren) {
  return (
    <Grid
      item
      xs={12} // Full width on extra-small screens
      sm={6} // Two columns on small screens
      md={4} // Three columns on medium screens
      lg={3} // Four columns on large screens
    >
      {children}
    </Grid>
  );
}

function SampleEditDialog({
  sample: originalSample,
  onRequestClose,
}: {
  sample?: Sample;
  onRequestClose: () => void;
}) {
  const { sampleTagGroupList } = useSampleTagGroupListQuery();
  const { sampleTagList } = useSampleTagListQuery();
  const { refetchSampleList } = useSampleListQuery();

  const sampleTagIdMap = useMemo(
    () =>
      sampleTagList.reduce<Record<number, SampleTag>>(
        (result, sampleTag) => ({ ...result, [sampleTag.id]: sampleTag }),
        {},
      ),
    [sampleTagList],
  );

  const [sample, setSample] = useState<Sample | SampleSaveFormat>(originalSample ?? { tags: {} });

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [isSubmitting, setSubmitting] = useState(false);

  const addSampleMutation = useMutation({ mutationFn: addSample });

  const updateSampleMutation = useMutation({ mutationFn: updateSample });

  const handleFieldValueChange = (fieldId: number, value: SampleTagValue | undefined) => {
    const sampleTag = sampleTagIdMap[fieldId];

    if (!sampleTag) {
      console.error('Invalid tag field ID:', fieldId);
      return;
    }

    if (value === undefined) {
      setSample((prevSample) => {
        const newSample = { ...prevSample };
        delete newSample.tags[fieldId];
        return newSample;
      });
      return;
    }

    // eslint-disable-next-line valid-typeof
    if (sampleTag.type !== 'image' && typeof value !== sampleTag.type) {
      console.error('Invalid tag field value:', value);
      return;
    }

    setSample((prevSample) => {
      const newSample = { ...prevSample };
      newSample.tags[fieldId] = value;
      return newSample;
    });
  };

  return (
    <>
      <Dialog open fullWidth maxWidth="lg">
        <DialogTitle>新增樣品</DialogTitle>
        <DialogContent>
          {sampleTagGroupList.map(({ tagFieldIds, name }) => (
            <Grid container key={name} spacing={2} alignItems="center" mb={1} pt={1}>
              <Grid item xs={12} md={3}>
                <Typography variant="h6">{name}</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={2}>
                  {tagFieldIds.flatMap((sampleTagId) => {
                    const sampleTag = sampleTagIdMap[sampleTagId];

                    if (!sampleTag) {
                      console.error('Invalid tag field ID:', sampleTagId);
                      return [];
                    }

                    const value = sample.tags[sampleTagId];

                    if (sampleTag.type === 'boolean' && (value === undefined || typeof value === 'boolean')) {
                      return (
                        <FieldWrapper key={sampleTagId.toString()}>
                          <BooleanField
                            label={sampleTag.name}
                            value={value}
                            onChange={(newValue) => handleFieldValueChange(sampleTagId, newValue)}
                          />
                        </FieldWrapper>
                      );
                    }

                    if (sampleTag.type === 'number' && (value === undefined || typeof value === 'number')) {
                      return (
                        <FieldWrapper key={sampleTagId.toString()}>
                          <NumberField
                            label={sampleTag.name}
                            value={value}
                            onChange={(newValue) => handleFieldValueChange(sampleTagId, newValue)}
                          />
                        </FieldWrapper>
                      );
                    }

                    if (sampleTag.type === 'string' && (value === undefined || typeof value === 'string')) {
                      return (
                        <FieldWrapper key={sampleTagId.toString()}>
                          <StringField
                            label={sampleTag.name}
                            value={value}
                            onChange={(newValue) => handleFieldValueChange(sampleTagId, newValue)}
                          />
                        </FieldWrapper>
                      );
                    }

                    if (sampleTag.type === 'image' && (value === undefined || typeof value === 'string')) {
                      return (
                        <FieldWrapper key={sampleTagId.toString()}>
                          <ImageField
                            value={value}
                            onChange={(newValue) => handleFieldValueChange(sampleTagId, newValue)}
                          />
                        </FieldWrapper>
                      );
                    }

                    return [];
                  })}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose} variant="outlined" disabled={isSubmitting}>
            取消
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                setSubmitting(true);

                if ('id' in sample) {
                  await updateSampleMutation.mutateAsync(sample);
                } else {
                  await addSampleMutation.mutateAsync(sample);
                }

                await refetchSampleList();
                onRequestClose();
              } catch (error) {
                if (error instanceof Error) {
                  setErrorMessage(error.message);
                } else {
                  console.error(error);
                }
              } finally {
                setSubmitting(false);
              }
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : '儲存'}
          </Button>
        </DialogActions>
      </Dialog>
      {errorMessage && (
        <ErrorMessage message={errorMessage} onRequestClose={() => setErrorMessage(undefined)} />
      )}
    </>
  );
}

export default SampleEditDialog;
