import { FormControlLabel, Checkbox } from '@mui/material';

function BooleanField({
  label,
  value,
  onChange,
}: {
  label: string;
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox checked={value ?? false} onChange={(e) => onChange(e.target.checked || undefined)} />
      }
      label={label}
    />
  );
}

export default BooleanField;
