import { DataGrid } from '@mui/x-data-grid';
import type { FactoryQuote } from 'src/models/factoryQuote';
import { useCustomerListQuery, useFactoryQuoteListQuery } from 'src/hooks';
import { useMemo } from 'react';
import type { Customer } from 'src/models/customer';
import { FACTORY_QUOTE_FIELD_TO_NAME } from './constants';

const columns = Object.keys(FACTORY_QUOTE_FIELD_TO_NAME)
  .map((field) => field as keyof typeof FACTORY_QUOTE_FIELD_TO_NAME)
  .map((field) => ({ field, width: 150, headerName: FACTORY_QUOTE_FIELD_TO_NAME[field] }));

function FactoryQuoteTable({
  onSelected,
  filterValues,
}: {
  onSelected?: (factoryQuote: FactoryQuote | undefined) => void;
  filterValues: string[];
}) {
  const { factoryQuoteList, isFactoryQuoteListLoading } = useFactoryQuoteListQuery();
  const { customerList, isCustomerListLoading } = useCustomerListQuery();

  const customerIdMap = useMemo(
    () =>
      customerList.reduce<Record<number, Customer>>(
        (result, customer) => ({ ...result, [customer.id]: customer }),
        {},
      ),
    [customerList],
  );

  const rows = factoryQuoteList.map((factoryQuote, index) => {
    const customer =
      factoryQuote.customerId === undefined ? undefined : customerIdMap[factoryQuote.customerId];

    return {
      id: index,
      createDate: new Date(factoryQuote.createDate).toLocaleString(),
      customerCode: customer?.code,
      customerName: customer?.name,
      creator: factoryQuote.creator,
      signStatus: factoryQuote.signStatus,
    };
  });

  return (
    <DataGrid
      loading={isFactoryQuoteListLoading || isCustomerListLoading}
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={(params) => {
        const rowIndex = params.row.id;
        onSelected?.(factoryQuoteList[rowIndex]);
      }}
      disableColumnFilter
      disableColumnSelector
      filterModel={{ items: [], quickFilterValues: filterValues }}
      sx={{ minHeight: 300 }}
      rowHeight={30}
    />
  );
}

export default FactoryQuoteTable;
