import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Container } from '@mui/material';
import SampleManagement from './components/SampleManagement/SampleManagement';
import NavBar from './components/shared/NavBar';
import CustomerMaintenance from './components/CustomerMaintenance/CustomerMaintenance';
import TagMaintenance from './components/TagMaintenance/TagMaintenance';
import FactoryQuoteMaintenance from './components/FactoryQuote/FactoryQuoteMaintenance';
import { useRouter } from './hooks/useRouter';
import BusinessQuoteMaintenance from './components/BusinessQuote/BusinessQuoteMaintenance';

const queryClient = new QueryClient();

function App() {
  const { pages, route, navigate } = useRouter();

  return (
    <QueryClientProvider client={queryClient}>
      <NavBar pages={pages} onClick={(newPage) => navigate({ page: newPage })} currentPage={route.page}>
        <Container sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', flexGrow: 1 }}>
          {route.page === '樣品管理' && <SampleManagement />}
          {route.page === '工廠報價' && <FactoryQuoteMaintenance />}
          {route.page === '業務報價' && <BusinessQuoteMaintenance />}
          {route.page === '客戶維護' && <CustomerMaintenance />}
          {route.page === '屬性維護' && <TagMaintenance />}
        </Container>
      </NavBar>
    </QueryClientProvider>
  );
}

export default App;
