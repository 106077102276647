import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFactoryQuoteTagForCustomerListQuery, useFactoryQuoteTagGroupListQuery } from 'src/hooks';
import type { FactoryQuoteTagGroup } from 'src/models/factoryQuote';
import type { BusinessQuoteModel } from 'src/models/businessQuote';
import TransferList from '../shared/TransferList';
import { FACTORY_QUOTE_TAG_FIELD_TO_NAME } from '../TagMaintenance/constants';
import { calculateBusinessQuoteModel } from './utils';

const ADDON_TAG_COLUMNS = [
  { field: 'groupName', headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME.groupName, width: 100 },
  { field: 'name', headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME.name, width: 100 },
  { field: 'cost', headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME.cost, width: 100 },
];

function FactoryQuoteTagSelectDialog({
  customerId,
  businessQuoteModel,
  onRequestClose,
  onSubmitted,
}: {
  customerId: number;
  businessQuoteModel: BusinessQuoteModel;
  onRequestClose: () => void;
  onSubmitted: (businessQuoteModel: BusinessQuoteModel) => void;
}) {
  const { factoryQuoteTagGroupList, isFactoryQuoteTagGroupListLoading } = useFactoryQuoteTagGroupListQuery();

  const factoryQuoteTagGroupIdMap = useMemo(
    () =>
      factoryQuoteTagGroupList.reduce<Record<number, FactoryQuoteTagGroup>>(
        (result, factoryQuoteTagGroup) => ({ ...result, [factoryQuoteTagGroup.id]: factoryQuoteTagGroup }),
        {},
      ),
    [factoryQuoteTagGroupList],
  );

  const { factoryQuoteTagForCustomerList, isFactoryQuoteTagForCustomerListLoading } =
    useFactoryQuoteTagForCustomerListQuery({
      customerId,
      modelType: businessQuoteModel.modelType,
      types: ['addon'],
    });

  const factoryQuoteTagForCustomerItems = useMemo(
    () =>
      factoryQuoteTagForCustomerList.map(({ id, name, group, cost }) => ({
        id,
        groupName: factoryQuoteTagGroupIdMap[group]?.name ?? '',
        name,
        cost,
      })),
    [factoryQuoteTagForCustomerList, factoryQuoteTagGroupIdMap],
  );

  const [selectedFactoryQuoteTagForCustomerItems, setSelectedFactoryQuoteTagForCustomerItems] = useState<
    (typeof factoryQuoteTagForCustomerItems)[number][]
  >([]);

  useEffect(() => {
    setSelectedFactoryQuoteTagForCustomerItems(
      businessQuoteModel.addon.flatMap(
        ({ id }) => factoryQuoteTagForCustomerItems.find((item) => item.id === id) ?? [],
      ),
    );
  }, [businessQuoteModel.addon, factoryQuoteTagForCustomerItems]);

  const onSubmit = useCallback(
    () =>
      onSubmitted(
        calculateBusinessQuoteModel({
          ...businessQuoteModel,
          addon: selectedFactoryQuoteTagForCustomerItems.map(({ id, cost }) => ({ id, cost })),
        }),
      ),
    [onSubmitted, businessQuoteModel, selectedFactoryQuoteTagForCustomerItems],
  );

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>修改加價項目</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 3 }}>
          <TransferList
            items={factoryQuoteTagForCustomerItems}
            selectedItems={selectedFactoryQuoteTagForCustomerItems}
            onChange={setSelectedFactoryQuoteTagForCustomerItems}
            columns={ADDON_TAG_COLUMNS}
            isLoading={isFactoryQuoteTagGroupListLoading || isFactoryQuoteTagForCustomerListLoading}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} variant="outlined">
          取消
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit}>
          更新
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FactoryQuoteTagSelectDialog;
