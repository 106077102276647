import type { Sample } from 'src/models/sample';
import { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import useSearchBar from 'src/hooks/useSearchBar';
import SampleEditDialog from './SampleEditDialog';
import SampleTable from './SampleTable';

function SampleManagement() {
  const [selectedSample, setSelectedSample] = useState<Sample | undefined>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [searchBar, { filterValues }] = useSearchBar();

  return (
    <>
      <Box>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            {searchBar}
            <Button
              sx={{ flexShrink: 0 }}
              variant="contained"
              onClick={() => setIsEditDialogOpen(true)}
              aria-label="add-sample"
            >
              新增樣品
            </Button>
          </Stack>
          <SampleTable
            onSelected={(sample) => {
              setSelectedSample(sample);
              setIsEditDialogOpen(true);
            }}
            filterValues={filterValues}
          />
        </Stack>
      </Box>
      {isEditDialogOpen && (
        <SampleEditDialog
          sample={selectedSample}
          onRequestClose={() => {
            setIsEditDialogOpen(false);
            setSelectedSample(undefined);
          }}
        />
      )}
    </>
  );
}

export default SampleManagement;
