import { DataGrid } from '@mui/x-data-grid';
import { useBusinessQuoteListQuery, useCustomerListQuery } from 'src/hooks';
import { useMemo } from 'react';
import type { Customer } from 'src/models/customer';
import type { BusinessQuote } from 'src/models/businessQuote';
import { BUSINESS_QUOTE_FIELD_TO_NAME } from './constants';

const columns = Object.keys(BUSINESS_QUOTE_FIELD_TO_NAME)
  .map((field) => field as keyof typeof BUSINESS_QUOTE_FIELD_TO_NAME)
  .map((field) => ({ field, width: 150, headerName: BUSINESS_QUOTE_FIELD_TO_NAME[field] }));

function FactoryQuoteTable({
  onSelected,
  filterValues,
}: {
  onSelected?: (factoryQuote: BusinessQuote | undefined) => void;
  filterValues: string[];
}) {
  const { businessQuoteList, isBusinessQuoteListLoading } = useBusinessQuoteListQuery();
  const { customerList, isCustomerListLoading } = useCustomerListQuery();

  const customerIdMap = useMemo(
    () =>
      customerList.reduce<Record<number, Customer>>(
        (result, customer) => ({ ...result, [customer.id]: customer }),
        {},
      ),
    [customerList],
  );

  const rows = businessQuoteList.map((businessQuote, index) => {
    const customer =
      businessQuote.customerId === undefined ? undefined : customerIdMap[businessQuote.customerId];

    return {
      id: index,
      createDate: new Date(businessQuote.createDate).toLocaleString(),
      customerCode: customer?.code,
      customerName: customer?.name,
      creator: businessQuote.creator,
      signStatus: businessQuote.signStatus,
    };
  });

  return (
    <DataGrid
      loading={isBusinessQuoteListLoading || isCustomerListLoading}
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={(params) => {
        const rowIndex = params.row.id;
        onSelected?.(businessQuoteList[rowIndex]);
      }}
      disableColumnFilter
      disableColumnSelector
      filterModel={{ items: [], quickFilterValues: filterValues }}
      sx={{ minHeight: 300 }}
      rowHeight={30}
    />
  );
}

export default FactoryQuoteTable;
