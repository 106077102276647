import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useFactoryQuoteItemListQuery } from 'src/hooks';
import type { BusinessQuoteDetail } from 'src/models/businessQuote';
import TransferList from '../shared/TransferList';
import { FACTORY_QUOTE_FIELD_TO_NAME, FACTORY_QUOTE_MODEL_FIELD_TO_NAME } from '../FactoryQuote/constants';
import { calculateBusinessQuoteModel } from './utils';

const FACTORY_QUOTE_MODEL_COLUMNS = [
  { field: 'createDate', headerName: FACTORY_QUOTE_FIELD_TO_NAME.createDate, width: 100 },
  { field: 'assembly', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.assembly, width: 100 },
  { field: 'modelName', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.modelName, width: 100 },
  { field: 'moldQuoteLocation', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.moldQuoteLocation, width: 100 },
  { field: 'quoteSum', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.quoteSum, width: 100 },
];

function FactoryQuoteModelSelectDialog({
  customerId,
  onRequestClose,
  onSubmitted,
}: {
  customerId: number;
  onRequestClose: () => void;
  onSubmitted: (businessQuoteModels: BusinessQuoteDetail['detail']) => void;
}) {
  const { factoryQuoteItemList, isFactoryQuoteItemListLoading } = useFactoryQuoteItemListQuery({
    customerId,
  });

  const factoryQuoteModelItems = useMemo(
    () =>
      factoryQuoteItemList.map(
        ({
          id,
          createDate,
          data: { assembly, modelType, modelName, moldQuoteLocation, direct, indirect },
          itemId,
        }) => ({
          id,
          createDate,
          assembly,
          modelType,
          modelName,
          moldQuoteLocation,
          quoteSum: [...direct, ...indirect].reduce((result, { cost = 0 }) => result + cost, 0),
          itemId,
        }),
      ),
    [factoryQuoteItemList],
  );

  const [selectedFactoryQuoteModelItems, setSelectedFactoryQuoteModelItems] = useState<
    (typeof factoryQuoteModelItems)[number][]
  >([]);

  const onSubmit = useCallback(() => {
    onSubmitted(
      selectedFactoryQuoteModelItems.map(({ itemId, modelType, modelName, moldQuoteLocation, quoteSum }) => ({
        itemId,
        data: calculateBusinessQuoteModel({
          customerModel: '',
          modelType,
          modelName,
          moldQuoteLocation,
          factoryQuoteSum: quoteSum,
          addon: [],
          cost: 0,
          profit: 0,
          orderNumPc: 0,
          orderNumDz: 0,
          fobPc: 0,
          fobDz: 0,
          costSum: 0,
          fobSum: 0,
          profitInPercentage: 0,
        }),
      })),
    );
  }, [onSubmitted, selectedFactoryQuoteModelItems]);

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>工廠報價選取視窗</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 3 }}>
          <TransferList
            items={factoryQuoteModelItems}
            selectedItems={selectedFactoryQuoteModelItems}
            onChange={setSelectedFactoryQuoteModelItems}
            columns={FACTORY_QUOTE_MODEL_COLUMNS}
            allowRepeatedSelection
            isLoading={isFactoryQuoteItemListLoading}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} variant="outlined">
          取消
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit}>
          匯入
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FactoryQuoteModelSelectDialog;
