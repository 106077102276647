import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useFactoryQuoteHistoryListQuery } from 'src/hooks';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import Dropdown from '../shared/Dropdown';
import FactoryQuoteDetailTable from '../FactoryQuote/FactoryQuoteDetailTable';

function FactoryQuoteHistoryButton({ label, itemId }: { label: ReactNode; itemId?: string }) {
  const { factoryQuoteHistoryList, isFactoryQuoteHistoryListLoading, isFactoryQuoteHistoryListFetched } =
    useFactoryQuoteHistoryListQuery({
      itemId,
    });

  const [isOpen, setIsOpen] = useState(false);

  const historyIndexOptions = useMemo(
    () =>
      factoryQuoteHistoryList?.map((item, index) => ({ value: index, label: item.version.toString() })) ?? [],
    [factoryQuoteHistoryList],
  );

  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number | undefined>();

  useEffect(() => {
    if (isFactoryQuoteHistoryListLoading || !isFactoryQuoteHistoryListFetched) return;
    setSelectedHistoryIndex(factoryQuoteHistoryList?.length ? factoryQuoteHistoryList.length - 1 : undefined);
  }, [factoryQuoteHistoryList, isFactoryQuoteHistoryListFetched, isFactoryQuoteHistoryListLoading]);

  const selectedHistory = factoryQuoteHistoryList?.[selectedHistoryIndex ?? -1];

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        {label}
        {factoryQuoteHistoryList && (
          <IconButton size="small" color="primary" aria-label="open history" onClick={() => setIsOpen(true)}>
            <HistoryIcon />
          </IconButton>
        )}
      </Stack>
      {isOpen && (
        <Dialog open maxWidth="lg">
          <DialogTitle>工廠報價歷史</DialogTitle>
          <DialogContent>
            {isFactoryQuoteHistoryListLoading && <CircularProgress />}
            {!isFactoryQuoteHistoryListLoading && (
              <Box sx={{ pt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Dropdown
                  label="版本"
                  options={historyIndexOptions}
                  value={selectedHistoryIndex}
                  onChange={setSelectedHistoryIndex}
                />
                <FactoryQuoteDetailTable factoryQuoteDetail={selectedHistory ? [selectedHistory] : []} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)} variant="outlined">
              關閉
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default FactoryQuoteHistoryButton;
