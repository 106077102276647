const apiUrl = process.env.REACT_APP_API_URL;

type GetOptions = { search?: string };
type Identifiable = { id: number } | { name: string };

async function get<T>(
  clientModel: string,
  serverModel: string,
  id: number,
  options?: GetOptions,
): Promise<T> {
  const response = await fetch(`${apiUrl}/api/${serverModel}/${id}/${options?.search ?? ''}`);

  if (response.status === 404) throw new Error('404');

  if (!response.ok) throw new Error(`Failed to fetch ${clientModel}`);

  return (await response.json()) as T;
}

export function createGetFunction<T>(
  clientModel: string,
  serverModel: string,
): (id: number, options?: GetOptions) => Promise<T>;
export function createGetFunction<T>(model: string): (id: number, options?: GetOptions) => Promise<T>;
export function createGetFunction<T>(clientModel: string, serverModel?: string) {
  return (id: number, options?: GetOptions) => get<T>(clientModel, serverModel ?? clientModel, id, options);
}

async function getList<T>(clientModel: string, serverModel: string, options?: GetOptions): Promise<T[]> {
  const response = await fetch(`${apiUrl}/api/${serverModel}/${options?.search ?? ''}`);

  if (!response.ok) throw new Error(`Failed to fetch ${clientModel} list`);

  return (await response.json()) as T[];
}

export function createGetListFunction<T>(
  clientModel: string,
  serverModel: string,
): (options?: GetOptions) => Promise<T[]>;
export function createGetListFunction<T>(model: string): (options?: GetOptions) => Promise<T[]>;
export function createGetListFunction<T>(clientModel: string, serverModel?: string) {
  return (options?: GetOptions) => getList<T>(clientModel, serverModel ?? clientModel, options);
}

async function add<T, R = T>(payload: T, clientModel: string, serverModel: string): Promise<R> {
  const response = await fetch(`${apiUrl}/api/${serverModel}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  if (!response.ok) throw new Error(`Failed to add ${clientModel}`);

  return (await response.json()) as R;
}

export function createAddFunction<T, R = T>(
  clientModel: string,
  serverModel: string,
): (payload: T) => Promise<R>;
export function createAddFunction<T, R = T>(model: string): (payload: T) => Promise<R>;
export function createAddFunction<T, R = T>(clientModel: string, serverModel?: string) {
  return (payload: T) => add<T, R>(payload, clientModel, serverModel ?? clientModel);
}

type UpdateOptions = { method?: 'PUT' | 'PATCH' };

async function update<T extends Identifiable>(
  payload: T,
  clientModel: string,
  serverModel: string,
  options?: UpdateOptions,
) {
  const response = await fetch(
    `${apiUrl}/api/${serverModel}/${'id' in payload ? payload.id : payload.name}/`,
    {
      method: options?.method ?? 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    },
  );

  if (!response.ok) throw new Error(`Failed to update ${clientModel}`);
}

export function createUpdateFunction<T extends Identifiable>(
  clientModel: string,
  serverModel: string,
): (payload: T, options?: UpdateOptions) => Promise<void>;
export function createUpdateFunction<T extends Identifiable>(
  model: string,
): (payload: T, options?: UpdateOptions) => Promise<void>;
export function createUpdateFunction<T extends Identifiable>(clientModel: string, serverModel?: string) {
  return (payload: T, options?: UpdateOptions) =>
    update(payload, clientModel, serverModel ?? clientModel, options);
}
