import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function Dropdown<T extends string | number>({
  label,
  options,
  value,
  onChange,
  disabled,
}: {
  label: string;
  options: { value: T; label: string }[];
  value: T | undefined;
  onChange: (value: T) => void;
  disabled?: boolean;
}) {
  return (
    <FormControl fullWidth disabled={disabled} size="small">
      <InputLabel id="dropdown-label">{label}</InputLabel>
      <Select
        labelId="dropdown-label"
        value={value ?? ''}
        label={label}
        onChange={(event) => onChange(event.target.value as T)}
      >
        {options.map(({ label: optionLabel, value: optionValue }) => (
          <MenuItem key={`dropdown-option-${optionValue}`} value={optionValue}>
            {optionLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default Dropdown;
