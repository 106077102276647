import { DataGrid } from '@mui/x-data-grid';
import { useSampleTagGroupListQuery, useSampleTagListQuery } from 'src/hooks';
import { useMemo } from 'react';
import type { SampleTag } from 'src/models/sample';
import { SAMPLE_TAG_FIELD_TO_NAME, SAMPLE_TAG_FIELD_TYPE_TO_NAME } from '../constants';

const columns = Object.keys(SAMPLE_TAG_FIELD_TO_NAME)
  .map((field) => field as keyof typeof SAMPLE_TAG_FIELD_TO_NAME)
  .map((field) => ({
    field,
    width: 150,
    headerName: SAMPLE_TAG_FIELD_TO_NAME[field],
  }));

function SampleTagTable({
  onSelected,
  filterValues,
}: {
  filterValues: string[];
  onSelected?: (factoryQuoteTag: SampleTag | undefined) => void;
}) {
  const { sampleTagGroupList, isSampleTagGroupListLoading } = useSampleTagGroupListQuery();
  const { sampleTagList, isSampleTagListLoading } = useSampleTagListQuery();

  const sampleTagIdMap = useMemo(
    () =>
      sampleTagList.reduce<Record<number, SampleTag>>(
        (result, sampleTag) => ({ ...result, [sampleTag.id]: sampleTag }),
        {},
      ),
    [sampleTagList],
  );

  const rows = sampleTagGroupList.flatMap((sampleTagGroup) => {
    return sampleTagGroup.tagFieldIds.flatMap((sampleTagId) => {
      const sampleTag = sampleTagIdMap[sampleTagId];
      if (!sampleTag) return [];
      return {
        id: `${sampleTagGroup.id}-${sampleTag.id}`,
        sampleTagGroupName: sampleTagGroup.name,
        sampleTagName: sampleTag.name,
        sampleTagType: SAMPLE_TAG_FIELD_TYPE_TO_NAME[sampleTag.type],
      };
    });
  });

  return (
    <DataGrid
      loading={isSampleTagGroupListLoading || isSampleTagListLoading}
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={(params) => {
        const [, tagId] = String(params.row.id).split('-');
        if (tagId === undefined) return;
        onSelected?.(sampleTagList.find(({ id }) => id === +tagId));
      }}
      disableColumnFilter
      disableColumnSelector
      filterModel={{ items: [], quickFilterValues: filterValues }}
      sx={{ minHeight: 300 }}
      rowHeight={30}
    />
  );
}

export default SampleTagTable;
