import { Autocomplete, TextField } from '@mui/material';

function FilterableDropdown<T extends { label: string }>({
  label,
  options,
  value,
  onChange,
  disabled,
}: {
  label: string;
  options: T[];
  value: T | null | undefined;
  onChange: (value: T | null) => void;
  disabled?: boolean;
}) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      filterSelectedOptions
      fullWidth
      disabled={disabled}
      size="small"
    />
  );
}
export default FilterableDropdown;
