import { TextField } from '@mui/material';

function StringField({
  label,
  value,
  onChange,
  disabled,
}: {
  label?: string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  disabled?: boolean;
}) {
  return (
    <TextField
      label={label}
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value || undefined)}
      variant="outlined"
      disabled={disabled}
      size="small"
      fullWidth
      {...(label
        ? {}
        : {
            sx: {
              '& .MuiInputBase-input': {
                padding: '2px 6px',
              },
            },
          })}
    />
  );
}

export default StringField;
