import { TextField } from '@mui/material';

function NumberField({
  label,
  value,
  onChange,
}: {
  label?: string;
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}) {
  return (
    <TextField
      fullWidth
      label={label}
      type="number"
      value={value ?? ''}
      onChange={(e) => {
        const newValue = e.target.value;
        onChange(newValue === '' ? undefined : +newValue);
      }}
      size="small"
      {...(label
        ? {}
        : {
            sx: {
              '& .MuiInputBase-input': {
                padding: '2px 6px',
              },
            },
          })}
    />
  );
}

export default NumberField;
