import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ErrorMessage({ message, onRequestClose }: { message: string; onRequestClose: () => void }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open
      message={message}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={onRequestClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}

export default ErrorMessage;
