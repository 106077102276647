import { useMutation } from '@tanstack/react-query';
import { addSampleTagGroup, addSampleTag, updateSampleTag, updateSampleTagGroup } from 'src/apis';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useMemo, useState } from 'react';
import type { SampleTag, SampleTagSaveFormat } from 'src/models/sample';
import { useSampleTagGroupListQuery, useSampleTagListQuery } from 'src/hooks';
import Dropdown from 'src/components/shared/Dropdown';
import StringField from 'src/components/shared/StringField';
import ErrorMessage from 'src/components/shared/ErrorMessage';
import { SAMPLE_TAG_FIELD_TO_NAME, SAMPLE_TAG_FIELD_TYPE_TO_NAME } from '../constants';

const sampleTagTypeOptions = Object.keys(SAMPLE_TAG_FIELD_TYPE_TO_NAME)
  .map((field) => field as keyof typeof SAMPLE_TAG_FIELD_TYPE_TO_NAME)
  .map((field) => ({
    value: field,
    label: SAMPLE_TAG_FIELD_TYPE_TO_NAME[field],
  }));

function SampleTagEditDialog({
  tag: originalTag,
  onRequestClose,
}: {
  tag?: SampleTag;
  onRequestClose: () => void;
}) {
  const { sampleTagGroupList, refetchSampleTagGroupList, setSampleTagGroupList } =
    useSampleTagGroupListQuery();

  const { refetchSampleTagList } = useSampleTagListQuery();

  const originalTagGroup = useMemo(
    () => (originalTag ? sampleTagGroupList?.find(({ id }) => id === originalTag.group) : undefined),
    [originalTag, sampleTagGroupList],
  );

  const [target, setTarget] = useState<'tag' | 'tagGroup'>('tag');

  const [selectedSampleTagGroupId, setSelectedSampleTagGroupId] = useState<number | undefined>(
    originalTagGroup?.id,
  );

  const [sampleTagName, setSampleTagName] = useState<string | undefined>(originalTag?.name);

  const [sampleTagGroupName, setSampleTagGroupName] = useState<string | undefined>(originalTagGroup?.name);

  const [sampleTagType, setSampleTagType] = useState<SampleTagSaveFormat['type'] | undefined>(
    originalTag?.type,
  );

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [isSubmitting, setSubmitting] = useState(false);

  const addSampleTagGroupMutation = useMutation({ mutationFn: addSampleTagGroup });

  const addSampleTagMutation = useMutation({ mutationFn: addSampleTag });

  const updateSampleTagGroupMutation = useMutation({ mutationFn: updateSampleTagGroup });

  const updateSampleTagMutation = useMutation({ mutationFn: updateSampleTag });

  const onSubmit = useMemo(() => {
    if (target === 'tag' && originalTag && sampleTagName !== undefined) {
      return async () => {
        try {
          setSubmitting(true);

          await updateSampleTagMutation.mutateAsync({
            ...originalTag,
            name: sampleTagName,
          });

          await refetchSampleTagList();
          onRequestClose();
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    if (
      target === 'tag' &&
      selectedSampleTagGroupId !== undefined &&
      sampleTagName !== undefined &&
      sampleTagType !== undefined
    ) {
      return async () => {
        try {
          setSubmitting(true);

          await addSampleTagMutation.mutateAsync({
            type: sampleTagType,
            name: sampleTagName,
            group: selectedSampleTagGroupId,
          });

          await Promise.all([refetchSampleTagGroupList, refetchSampleTagList]);
          onRequestClose();
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    if (target === 'tagGroup' && originalTagGroup && sampleTagGroupName !== undefined) {
      return async () => {
        try {
          setSubmitting(true);

          await updateSampleTagGroupMutation.mutateAsync({ ...originalTagGroup, name: sampleTagGroupName });

          await refetchSampleTagGroupList();
          setTarget('tag');
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    if (target === 'tagGroup' && sampleTagGroupName !== undefined) {
      return async () => {
        try {
          setSubmitting(true);

          const newSampleTagGroup = await addSampleTagGroupMutation.mutateAsync({ name: sampleTagGroupName });
          setSampleTagGroupList((prev) => [...prev, newSampleTagGroup]);
          setSelectedSampleTagGroupId(newSampleTagGroup.id);
          setSampleTagGroupName(undefined);

          await refetchSampleTagGroupList();
          setTarget('tag');
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    return undefined;
  }, [
    addSampleTagGroupMutation,
    addSampleTagMutation,
    onRequestClose,
    originalTag,
    originalTagGroup,
    refetchSampleTagGroupList,
    refetchSampleTagList,
    sampleTagGroupName,
    sampleTagName,
    sampleTagType,
    selectedSampleTagGroupId,
    setSampleTagGroupList,
    target,
    updateSampleTagGroupMutation,
    updateSampleTagMutation,
  ]);

  return (
    <>
      <Dialog open fullWidth maxWidth="lg">
        {target === 'tag' && <DialogTitle>{`${originalTag ? '編輯' : '新增'}樣品屬性`}</DialogTitle>}
        {target === 'tagGroup' && <DialogTitle>{`${originalTag ? '編輯' : '新增'}項目`}</DialogTitle>}
        <DialogContent>
          <Box sx={{ pt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {target === 'tag' && (
              <>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Dropdown
                    label={SAMPLE_TAG_FIELD_TO_NAME.sampleTagGroupName}
                    options={sampleTagGroupList.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={selectedSampleTagGroupId}
                    onChange={setSelectedSampleTagGroupId}
                    disabled={!!originalTag}
                  />
                  <Button
                    variant="contained"
                    onClick={() => setTarget('tagGroup')}
                    aria-label="add-sample-tag-group"
                    sx={{ flexShrink: 0 }}
                  >
                    {`${originalTag ? '編輯' : '新增'}項目`}
                  </Button>
                </Stack>
                <StringField
                  label={SAMPLE_TAG_FIELD_TO_NAME.sampleTagName}
                  value={sampleTagName}
                  onChange={setSampleTagName}
                />
                <Dropdown
                  label={SAMPLE_TAG_FIELD_TO_NAME.sampleTagType}
                  options={sampleTagTypeOptions}
                  value={sampleTagType}
                  onChange={setSampleTagType}
                  disabled={!!originalTag}
                />
              </>
            )}
            {target === 'tagGroup' && (
              <StringField
                label={SAMPLE_TAG_FIELD_TO_NAME.sampleTagGroupName}
                value={sampleTagGroupName}
                onChange={setSampleTagGroupName}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (target === 'tag') {
                onRequestClose();
              } else if (target === 'tagGroup') {
                setTarget('tag');
              }
            }}
            variant="outlined"
            disabled={isSubmitting}
          >
            取消
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit || isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : '儲存'}
          </Button>
        </DialogActions>
      </Dialog>
      {errorMessage && (
        <ErrorMessage message={errorMessage} onRequestClose={() => setErrorMessage(undefined)} />
      )}
    </>
  );
}

export default SampleTagEditDialog;
