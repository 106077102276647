import type { QueryFunction } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';

type UseQueryReturn<T, K extends string> = {
  [key in K]: T;
} & {
  [key in `set${Capitalize<K>}`]: Dispatch<SetStateAction<T>>;
} & {
  [key in `refetch${Capitalize<K>}`]: () => Promise<void>;
} & {
  [key in `is${Capitalize<K>}Loading`]: boolean;
} & {
  [key in `is${Capitalize<K>}Fetched`]: boolean;
};

export function useGetListQuery<T, K extends string>({
  queryFn,
  queryKey,
  disabled,
}: {
  queryFn: QueryFunction<T[]>;
  queryKey: K;
  disabled?: boolean;
}): UseQueryReturn<T[], K> {
  const {
    data: dataFromServer = [],
    refetch,
    isLoading,
    isFetched,
  } = useQuery({ queryKey: [queryKey], queryFn, enabled: !disabled });

  const [data, setData] = useState(dataFromServer);

  useEffect(() => {
    const timeout = setTimeout(() => setData(dataFromServer), 100);
    return () => clearTimeout(timeout);
  }, [dataFromServer]);

  return useMemo(() => {
    const capitalizedQueryKey = queryKey.charAt(0).toUpperCase() + queryKey.slice(1);

    return {
      [queryKey]: data,
      [`set${capitalizedQueryKey}`]: setData,
      [`refetch${capitalizedQueryKey}`]: refetch,
      [`is${capitalizedQueryKey}Loading`]: isLoading,
      [`is${capitalizedQueryKey}Fetched`]: isFetched,
    } as UseQueryReturn<T[], K>;
  }, [queryKey, data, refetch, isLoading, isFetched]);
}

export function useGetQuery<T, K extends string>({
  queryFn,
  queryKey,
  disabled,
}: {
  queryFn: QueryFunction<T | null>;
  queryKey: K;
  disabled?: boolean;
}): UseQueryReturn<T | undefined, K> {
  const {
    data: dataFromServer,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [queryKey],
    queryFn,
    enabled: !disabled,
    retry: (failureCount, error) => {
      if (error.message === '404' || failureCount >= 3) {
        return false;
      }
      return true;
    },
  });

  const [data, setData] = useState<T | undefined>();
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(dataFromServer ?? undefined);
      setIsFetched(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [dataFromServer]);

  return useMemo(() => {
    const capitalizedQueryKey = queryKey.charAt(0).toUpperCase() + queryKey.slice(1);

    return {
      [queryKey]: data,
      [`set${capitalizedQueryKey}`]: setData,
      [`refetch${capitalizedQueryKey}`]: refetch,
      [`is${capitalizedQueryKey}Loading`]: isLoading,
      [`is${capitalizedQueryKey}Fetched`]: isFetched,
    } as UseQueryReturn<T | undefined, K>;
  }, [queryKey, data, refetch, isLoading, isFetched]);
}
