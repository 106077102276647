import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomerListQuery, useFactoryQuoteListQuery, useFactoryQuoteQuery } from 'src/hooks';
import type { Customer } from 'src/models/customer';
import type { FactoryQuote, FactoryQuoteDetail, FactoryQuoteModel } from 'src/models/factoryQuote';
import { useMutation } from '@tanstack/react-query';
import { addFactoryQuote, updateFactoryQuote } from 'src/apis';
import { FACTORY_QUOTE_TAG_FIELD_TO_NAME } from '../TagMaintenance/constants';
import FactoryQuoteModelEditDialog from './FactoryQuoteModelEditDialog';
import FactoryQuoteDetailTable from './FactoryQuoteDetailTable';
import FilterableDropdown from '../shared/FilterableDropdown';
import ErrorMessage from '../shared/ErrorMessage';
import FactoryQuoteHistoryButton from '../FactoryQuoteHistory/FactoryQuoteHistoryButton';

function FactoryQuoteFormBase({
  factoryQuote: originalFactoryQuote,
  onRequestClose,
}: {
  factoryQuote: FactoryQuoteDetail | undefined;
  onRequestClose: () => void;
}) {
  const { customerList } = useCustomerListQuery();
  const { refetchFactoryQuoteList } = useFactoryQuoteListQuery();

  const customerOptions = useMemo(
    () => customerList.map(({ id, code, name }) => ({ value: id, label: `${code} (${name})` })),
    [customerList],
  );

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<{
    label: string;
    value: Customer['id'];
  } | null>(null);

  useEffect(() => {
    if (!originalFactoryQuote) return;
    setSelectedCustomerOption(
      customerOptions.find(({ value }) => value === originalFactoryQuote.customerId) ?? null,
    );
  }, [customerOptions, originalFactoryQuote]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [selectedFactoryQuoteDetailIndex, setSelectedFactoryQuoteDetailIndex] = useState(-1);

  const [factoryQuoteDetail, setFactoryQuoteDetail] = useState(originalFactoryQuote?.detail ?? []);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [isSubmitting, setSubmitting] = useState(false);

  const onModelEditDialogSubmit = useCallback(
    (newFactoryQuoteModel: FactoryQuoteModel) => {
      setIsEditDialogOpen(false);
      setFactoryQuoteDetail((prev) => {
        const detailItem = prev[selectedFactoryQuoteDetailIndex];
        if (detailItem) {
          return prev.toSpliced(selectedFactoryQuoteDetailIndex, 1, {
            ...detailItem,
            data: newFactoryQuoteModel,
          });
        }
        return [...prev, { data: newFactoryQuoteModel }];
      });
    },
    [selectedFactoryQuoteDetailIndex],
  );

  const onModelTableRequestEdit = useCallback(
    (modelIndex: number) => {
      setSelectedFactoryQuoteDetailIndex(modelIndex);
      setIsEditDialogOpen(true);
    },
    [setIsEditDialogOpen, setSelectedFactoryQuoteDetailIndex],
  );

  const addFactoryQuoteMutation = useMutation({ mutationFn: addFactoryQuote });

  const updateFactoryQuoteMutation = useMutation({ mutationFn: updateFactoryQuote });

  const onSubmit = useMemo(() => {
    if (
      selectedCustomerOption !== null &&
      !factoryQuoteDetail.flatMap(({ data: { indirect } }) => indirect).some(({ cost }) => cost === undefined)
    ) {
      return async () => {
        try {
          setSubmitting(true);

          if (originalFactoryQuote) {
            await updateFactoryQuoteMutation.mutateAsync({
              ...originalFactoryQuote,
              detail: factoryQuoteDetail,
            });
          } else {
            await addFactoryQuoteMutation.mutateAsync({
              customerId: selectedCustomerOption.value,
              detail: factoryQuoteDetail,
            });
          }

          await refetchFactoryQuoteList();
          onRequestClose();
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    return undefined;
  }, [
    addFactoryQuoteMutation,
    factoryQuoteDetail,
    onRequestClose,
    originalFactoryQuote,
    refetchFactoryQuoteList,
    selectedCustomerOption,
    updateFactoryQuoteMutation,
  ]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, overflow: 'hidden', flexGrow: 1 }}>
        <h1>工廠報價單</h1>
        <Stack direction="row" alignItems="center" gap={1}>
          <FilterableDropdown
            label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.customerCode}
            options={customerOptions}
            value={selectedCustomerOption}
            onChange={(newValue) => setSelectedCustomerOption(newValue)}
            disabled={!!originalFactoryQuote}
          />
          <Button
            disabled={selectedCustomerOption === null}
            sx={{ flexShrink: 0 }}
            variant="contained"
            aria-label="add-model"
            onClick={() => setIsEditDialogOpen(true)}
          >
            新增形體
          </Button>
        </Stack>
        <FactoryQuoteDetailTable
          factoryQuoteDetail={factoryQuoteDetail}
          setFactoryQuoteDetail={setFactoryQuoteDetail}
          onRequestEdit={onModelTableRequestEdit}
          renderQuoteSum={({ itemId, label }) => <FactoryQuoteHistoryButton itemId={itemId} label={label} />}
        />
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1} py={1}>
          <Button onClick={onRequestClose} variant="outlined" disabled={isSubmitting}>
            取消
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit || isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : '儲存'}
          </Button>
        </Stack>
        {isEditDialogOpen && selectedCustomerOption && (
          <FactoryQuoteModelEditDialog
            customerId={selectedCustomerOption.value}
            factoryQuoteModel={factoryQuoteDetail[selectedFactoryQuoteDetailIndex]?.data}
            onRequestClose={() => {
              setIsEditDialogOpen(false);
              setSelectedFactoryQuoteDetailIndex(-1);
            }}
            onSubmitted={onModelEditDialogSubmit}
          />
        )}
      </Box>
      {errorMessage && (
        <ErrorMessage message={errorMessage} onRequestClose={() => setErrorMessage(undefined)} />
      )}
    </>
  );
}

function FactoryQuoteForm({
  factoryQuoteId,
  onRequestClose,
}: {
  factoryQuoteId?: FactoryQuote['id'];
  onRequestClose: () => void;
}) {
  const { factoryQuote, isFactoryQuoteLoading, isFactoryQuoteFetched } = useFactoryQuoteQuery(factoryQuoteId);

  useEffect(() => {
    if (factoryQuoteId === undefined || !isFactoryQuoteFetched || factoryQuote) return;

    onRequestClose();
  }, [factoryQuoteId, isFactoryQuoteFetched, onRequestClose, factoryQuote]);

  if (factoryQuote?.id !== factoryQuoteId || !isFactoryQuoteFetched || isFactoryQuoteLoading) {
    return (
      <>
        <h1>工廠報價單</h1>
        <CircularProgress />
      </>
    );
  }

  return <FactoryQuoteFormBase factoryQuote={factoryQuote} onRequestClose={onRequestClose} />;
}

export default FactoryQuoteForm;
