import { useDeferredValue, useMemo, useState } from 'react';
import { TextField } from '@mui/material';

function SearchBar({
  value,
  onChange,
  noShrink,
}: {
  value: string;
  onChange: (value: string) => void;
  noShrink?: boolean;
}) {
  return (
    <TextField
      sx={{ flexShrink: noShrink ? 0 : 1 }}
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      placeholder="Search..."
      size="small"
    />
  );
}

function useSearchBar(options?: {
  noShrink?: boolean;
}): [JSX.Element, { filterString: string; filterValues: string[] }] {
  const [filterString, setFilterString] = useState('');

  const deferredFilterString = useDeferredValue(filterString);

  const searchBar = useMemo(
    () => <SearchBar value={filterString} onChange={setFilterString} noShrink={options?.noShrink} />,
    [filterString, options?.noShrink],
  );

  return [
    searchBar,
    useMemo(
      () => ({
        filterString: deferredFilterString,
        filterValues: deferredFilterString.split(' ').filter(Boolean),
      }),
      [deferredFilterString],
    ),
  ];
}

export default useSearchBar;
