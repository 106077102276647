import { useRouter } from 'src/hooks/useRouter';
import Tabs from '../shared/Tabs';
import FactoryQuoteTagMaintenance from './FactoryQuoteTag/FactoryQuoteTagMaintenance';
import SampleTagMaintenance from './SampleTag/SampleTagMaintenance';

function TagMaintenance() {
  const { route, navigate } = useRouter();

  return (
    <Tabs
      tabs={[
        { name: '樣品屬性維護', children: <SampleTagMaintenance /> },
        { name: '工廠報價屬性維護', children: <FactoryQuoteTagMaintenance /> },
      ]}
      currentTab={route.tab ?? 0}
      onTabChange={(newTab) => navigate({ tab: newTab })}
    />
  );
}

export default TagMaintenance;
