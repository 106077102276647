import type { FactoryQuoteModel, FactoryQuoteTagGroup } from 'src/models/factoryQuote';

export const FACTORY_QUOTE_FIELD_TO_NAME = {
  createDate: '日期',
  customerCode: '客戶代號',
  customerName: '客戶名稱',
  creator: '建立者',
  signStatus: '簽核狀態',
} as const;

export const FACTORY_QUOTE_MODEL_FIELD_TO_NAME = {
  moldQuoteLocation: '模具位置報價',
  assembly: '組裝',
  modelType: '形體類別',
  modelName: '形體名稱',
  usdCost: '美金成本',
  usdCost2: '美金成本(支)',
  exportMarkup: '出口預加',
  remarks: '備註',
  moldLocation: '目前模具位置',
  quoteSum: '報價小計(NT)',
} as const;

export const FACTORY_QUOTE_MODEL_MOLD_LOCATION_OPTIONS: { value: string; label: string }[] = [
  { value: '永康廠', label: '永康廠' },
  { value: '大新', label: '大新' },
] as const;

export const FACTORY_QUOTE_MODEL_ASSEMBLY_OPTIONS: { value: FactoryQuoteModel['assembly']; label: string }[] =
  [
    { value: '廠內', label: '廠內' },
    { value: '托外', label: '托外' },
  ] as const;

export const FACTORY_QUOTE_TAG_GROUP_TYPES: FactoryQuoteTagGroup['type'][] = ['direct', 'indirect'] as const;
