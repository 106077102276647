import { Box, Dialog } from '@mui/material';
import { useState } from 'react';

function ImagePreview({ imageSrc, thumbnailSize = 50 }: { imageSrc: string; thumbnailSize?: number }) {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => setOpen(true);

  const handleMouseLeave = () => setOpen(false);

  return (
    <>
      <Box
        component="img"
        src={imageSrc}
        alt="Thumbnail"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: thumbnailSize,
          height: thumbnailSize,
          objectFit: 'cover',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      />
      <Dialog open={open} sx={{ pointerEvents: 'none', zIndex: 1000 }}>
        <Box
          component="img"
          src={imageSrc}
          alt="Preview"
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Dialog>
    </>
  );
}

export default ImagePreview;
