import type { PropsWithChildren, MouseEvent } from 'react';
import { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function ResponsiveAppBar<T extends readonly string[]>({
  children,
  pages,
  currentPage,
  onClick,
}: PropsWithChildren<{
  pages: T;
  currentPage: T[number];
  onClick: (page: T[number]) => void;
}>) {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="static"
        sx={{
          backgroundColor: '#1565c0',
          boxShadow: 'none',
          padding: 0,
          display: 'flex',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page}
                    onClick={() => {
                      handleCloseNavMenu();
                      onClick(page);
                    }}
                    selected={page === currentPage}
                  >
                    <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => {
                    handleCloseNavMenu();
                    onClick(page);
                  }}
                  sx={{
                    color: page === currentPage ? 'white' : '#e0e0e0',
                    fontWeight: page === currentPage ? 'bolder' : 'bold',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: page === currentPage ? '#0d47a1' : '#424242',
                    },
                    display: 'block',
                    height: '100%',
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', pt: 3 }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default ResponsiveAppBar;
