import { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import useSearchBar from 'src/hooks/useSearchBar';
import { DataGrid } from '@mui/x-data-grid';

type Column = { field: string; width: number; headerName: string };
type Identifable = { id: number };

export function ItemList<T extends Identifable & { [key: string]: unknown }>({
  items,
  columns,
  onSelected,
}: {
  items: T[];
  columns: Column[];
  onSelected: (id: number) => void;
}) {
  const [searchBar, { filterValues }] = useSearchBar({ noShrink: true });

  const rows = items.map((item, index) => ({ ...item, id: index }));

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, height: 317 }}>
      {searchBar}
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        onRowClick={(params) => {
          const item = items[params.row.id ?? -1];
          if (item) onSelected(item.id);
        }}
        disableColumnFilter
        disableColumnSelector
        disableColumnResize
        disableColumnSorting
        disableColumnMenu
        filterModel={{ items: [], quickFilterValues: filterValues }}
        rowHeight={30}
      />
    </Box>
  );
}

export default function TransferList<T extends Identifable>({
  title,
  items,
  selectedItems: defaultSelectedItems,
  onChange,
  columns,
  allowRepeatedSelection,
  isLoading,
}: {
  title?: string;
  items: T[];
  selectedItems?: T[];
  onChange?: (selectedItems: T[]) => void;
  columns: Column[];
  allowRepeatedSelection?: boolean;
  isLoading?: boolean;
}) {
  const [selectedIdNums, setSelectedIdNums] = useState<Record<number, number>>({});

  useEffect(() => {
    if (!defaultSelectedItems) return;

    setSelectedIdNums(
      defaultSelectedItems.reduce<typeof selectedIdNums>((result, item) => {
        const index = items.findIndex((i) => i.id === item.id);
        if (index < 0) return result;
        return { ...result, [item.id]: (result[item.id] ?? 0) + 1 };
      }, {}),
    );
  }, [defaultSelectedItems, items]);

  const onItemClick = useCallback(
    (id: number, position: 'left' | 'right') => {
      if (onChange) {
        if (position === 'left') {
          selectedIdNums[id] = (selectedIdNums[id] ?? 0) + 1;
        } else {
          selectedIdNums[id] = (selectedIdNums[id] ?? 0) - 1;
          if (selectedIdNums[id] === 0) delete selectedIdNums[id];
        }

        onChange(items.flatMap((item) => Array(selectedIdNums[item.id] ?? 0).fill(item)));

        return;
      }
      setSelectedIdNums((prev) => {
        const next = { ...prev };

        if (position === 'left') {
          next[id] = (next[id] ?? 0) + 1;
        } else {
          next[id] = (next[id] ?? 0) - 1;
          if (next[id] === 0) delete next[id];
        }

        return next;
      });
    },
    [items, onChange, selectedIdNums],
  );

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      {title && <Typography variant="h6">{title}</Typography>}
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Stack direction="row" alignItems="start" gap={1}>
          <ItemList
            columns={columns}
            items={allowRepeatedSelection ? items : items.filter((item) => !selectedIdNums[item.id])}
            onSelected={(id) => onItemClick(id, 'left')}
          />
          <ItemList
            columns={columns}
            items={items.flatMap((item) => Array(selectedIdNums[item.id] ?? 0).fill(item))}
            onSelected={(id) => onItemClick(id, 'right')}
          />
        </Stack>
      )}
    </Box>
  );
}
