import { useSyncExternalStore } from 'react';

const pages = ['樣品管理', '工廠報價', '業務報價', '客戶維護', '屬性維護'] as const;

type Route = { page: (typeof pages)[number]; tab?: number; id?: number };

type Listener = () => void;

function updateUrl(route: Route) {
  const url = new URL(window.location.href);
  url.pathname = `/${route.page}`;

  if (typeof route.tab === 'number') {
    url.searchParams.set('tab', route.tab.toString());
  } else {
    url.searchParams.delete('tab');
  }

  if (typeof route.id === 'number') {
    url.searchParams.set('id', route.id.toString());
  } else {
    url.searchParams.delete('id');
  }

  window.history.replaceState({}, '', url.href);
}

function getDefaultRoute() {
  const url = new URL(window.location.href);

  const pathname = decodeURIComponent(url.pathname.slice(1));
  const defaultPathname = pages.find((p) => p === pathname) ?? pages[0];

  const tab = +(url.searchParams.get('tab') ?? '0');
  const defaultTab = Number.isInteger(tab) && defaultPathname === '屬性維護' ? tab : undefined;

  const idString = url.searchParams.get('id');
  const id = idString ? +idString : undefined;
  const defaultId = Number.isInteger(id) && defaultPathname === '工廠報價' ? id : undefined;

  const defaultRoute: Route = { page: defaultPathname, tab: defaultTab, id: defaultId };
  if (pathname !== defaultPathname || tab !== defaultTab || id !== defaultId) {
    updateUrl(defaultRoute);
  }

  return defaultRoute;
}

function createRouteManager() {
  let route: Route = getDefaultRoute();
  const listeners = new Set<Listener>();
  const notifyListeners = () => listeners.forEach((listener) => listener());

  return {
    navigate(newRoute: Partial<Route>) {
      route = { ...route, ...newRoute };

      if (route.page === '屬性維護') {
        route.tab = route.tab ?? 0;
      } else {
        delete route.tab;
      }

      if (!['工廠報價', '業務報價'].includes(route.page) || newRoute.id === undefined) {
        delete route.id;
      }

      updateUrl(route);
      notifyListeners();
    },
    subscribe(listener: Listener) {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
    getSnapshot: () => route,
  };
}

const routeManager = createRouteManager();

export function useRouter() {
  const route = useSyncExternalStore(routeManager.subscribe, routeManager.getSnapshot);

  return { pages, route, navigate: routeManager.navigate };
}
