import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useFactoryQuoteTagListQuery } from 'src/hooks';
import type { FactoryQuoteDetail, FactoryQuoteTag } from 'src/models/factoryQuote';
import { FACTORY_QUOTE_MODEL_FIELD_TO_NAME } from './constants';
import NumberField from '../shared/NumberField';
import StringField from '../shared/StringField';
import DeleteButton from '../shared/DeleteButton';

const CELL_SIZE = { width: 200, height: 30 };

function FactoryQuoteDetailTable({
  factoryQuoteDetail,
  setFactoryQuoteDetail,
  onRequestEdit,
  renderQuoteSum,
}: {
  factoryQuoteDetail: FactoryQuoteDetail['detail'];
  setFactoryQuoteDetail?: Dispatch<SetStateAction<FactoryQuoteDetail['detail']>>;
  onRequestEdit?: (index: number) => void;
  renderQuoteSum?: (props: { itemId: string | undefined; label: ReactNode }) => ReactNode;
}) {
  const { factoryQuoteTagList } = useFactoryQuoteTagListQuery();

  const factoryQuoteTagIdMap = useMemo(
    () =>
      factoryQuoteTagList.reduce<Record<number, FactoryQuoteTag>>(
        (result, factoryQuoteTag) => ({ ...result, [factoryQuoteTag.id]: factoryQuoteTag }),
        {},
      ),
    [factoryQuoteTagList],
  );

  const readonly = !(setFactoryQuoteDetail || onRequestEdit);

  const columns = useMemo(
    () => [
      ...(readonly ? [] : [{ field: 'actions', headerName: '' }]),
      { field: 'moldQuoteLocation', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.moldQuoteLocation },
      { field: 'assembly', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.assembly },
      { field: 'modelType', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.modelType },
      { field: 'modelName', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.modelName },
      ...Array.from(
        new Set(factoryQuoteDetail.flatMap(({ data: { direct } }) => direct).map(({ id }) => id)),
      ).map((tagId) => ({
        field: tagId.toString(),
        headerName: factoryQuoteTagIdMap[tagId]?.name ?? '',
      })),
      ...Array.from(
        new Set(factoryQuoteDetail.flatMap(({ data: { indirect } }) => indirect).map(({ id }) => id)),
      ).map((tagId) => ({
        field: tagId.toString(),
        headerName: factoryQuoteTagIdMap[tagId]?.name ?? '',
      })),
      { field: 'quoteSum', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.quoteSum },
      { field: 'usdCost', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.usdCost },
      { field: 'usdCost2', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.usdCost2 },
      { field: 'exportMarkup', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.exportMarkup },
      { field: 'remarks', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.remarks },
      { field: 'moldLocation', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.moldLocation },
    ],
    [factoryQuoteDetail, factoryQuoteTagIdMap, readonly],
  );

  const rows = useMemo(
    () =>
      factoryQuoteDetail.map<{ id: string } & Record<string, ReactNode>>(
        (
          { data: { direct, indirect, usdCost, usdCost2, exportMarkup, remarks, ...rest }, itemId },
          detailIndex,
        ) => {
          const costItems = [
            ...direct.map(({ id, cost }, index) => ({ id, cost, type: 'direct' as const, index })),
            ...indirect.map(({ id, cost }, index) => ({ id, cost, type: 'indirect' as const, index })),
          ].reduce<{ quoteSum?: number } & Record<string, ReactNode>>(
            (result, { id, cost, type, index }) => ({
              ...result,
              quoteSum: (result.quoteSum ?? 0) + (cost ?? 0),
              [id.toString()]:
                type === 'direct' || !setFactoryQuoteDetail ? (
                  cost
                ) : (
                  <NumberField
                    value={cost}
                    onChange={(value) => {
                      setFactoryQuoteDetail((prev) => {
                        const detailItem = prev[detailIndex]
                          ? {
                              ...prev[detailIndex],
                              data: {
                                ...prev[detailIndex].data,
                                [type]: prev[detailIndex].data[type].with(index, { id, cost: value }),
                              },
                            }
                          : prev[detailIndex];

                        return detailItem ? prev.with(detailIndex, detailItem) : prev;
                      });
                    }}
                  />
                ),
            }),
            {},
          );

          const editableNumberFields = [
            { key: 'usdCost', value: usdCost },
            { key: 'usdCost2', value: usdCost2 },
            { key: 'exportMarkup', value: exportMarkup },
          ].reduce<Record<string, ReactNode>>((result, { key, value }) => {
            return {
              ...result,
              [key]: setFactoryQuoteDetail ? (
                <NumberField
                  value={value ?? undefined}
                  onChange={(newValue) => {
                    setFactoryQuoteDetail((prev) => {
                      const detailItem = prev[detailIndex]
                        ? {
                            ...prev[detailIndex],
                            data: { ...prev[detailIndex].data, [key]: newValue ?? null },
                          }
                        : prev[detailIndex];

                      return detailItem ? prev.with(detailIndex, detailItem) : prev;
                    });
                  }}
                />
              ) : (
                value
              ),
            };
          }, {});

          const editableStringFields = [{ key: 'remarks', value: remarks }].reduce<Record<string, ReactNode>>(
            (result, { key, value }) => ({
              ...result,
              [key]: setFactoryQuoteDetail ? (
                <StringField
                  value={value ?? undefined}
                  onChange={(newValue = '') => {
                    setFactoryQuoteDetail((prev) => {
                      const detailItem = prev[detailIndex]
                        ? { ...prev[detailIndex], data: { ...prev[detailIndex].data, [key]: newValue } }
                        : prev[detailIndex];

                      return detailItem ? prev.with(detailIndex, detailItem) : prev;
                    });
                  }}
                />
              ) : (
                value
              ),
            }),
            {},
          );

          return {
            ...rest,
            ...costItems,
            ...(renderQuoteSum ? { quoteSum: renderQuoteSum({ itemId, label: costItems.quoteSum }) } : {}),
            ...editableNumberFields,
            ...editableStringFields,
            actions: (
              <ButtonGroup variant="text">
                {onRequestEdit && <Button onClick={() => onRequestEdit(detailIndex)}>編輯</Button>}
                {setFactoryQuoteDetail && (
                  <DeleteButton
                    onClick={() => setFactoryQuoteDetail((prev) => prev.toSpliced(detailIndex, 1))}
                  />
                )}
              </ButtonGroup>
            ),
            id: detailIndex.toString(),
          };
        },
      ),
    [factoryQuoteDetail, onRequestEdit, renderQuoteSum, setFactoryQuoteDetail],
  );

  return (
    <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
      {rows.length > 0 && (
        <Stack direction="row" gap={2}>
          <Stack>
            {columns.map((column) => (
              <Box
                key={column.field}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: CELL_SIZE.width,
                  height: CELL_SIZE.height,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {column.headerName}
                </Typography>
              </Box>
            ))}
          </Stack>
          {rows.map((row) => (
            <Stack key={row.id}>
              {columns.map((column) => (
                <Box
                  key={column.field}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: CELL_SIZE.width,
                    height: CELL_SIZE.height,
                  }}
                >
                  {row[column.field] ?? ''}
                </Box>
              ))}
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
}
export default FactoryQuoteDetailTable;
