import { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  CircularProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import StringField from 'src/components/shared/StringField';
import type { Customer, CustomerSaveFormat } from 'src/models/customer';
import { useCustomerListQuery } from 'src/hooks';
import { addCustomer, updateCustomer } from 'src/apis';
import { CUSTOMER_FIELDS } from './constants';
import ErrorMessage from '../shared/ErrorMessage';

function validateCustomer(
  customer: Partial<Customer | CustomerSaveFormat>,
): customer is Customer | CustomerSaveFormat {
  return CUSTOMER_FIELDS.map(({ field }) => field).every((field) => customer[field] !== undefined);
}

function CustomerEditDialog({
  customer: originalCustomer,
  onRequestClose,
}: {
  customer?: Customer;
  onRequestClose: () => void;
}) {
  const { refetchCustomerList } = useCustomerListQuery();

  const [customer, setCustomer] = useState<Partial<Customer | CustomerSaveFormat>>(originalCustomer ?? {});

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [isSubmitting, setSubmitting] = useState(false);

  const addCustomerMutation = useMutation({ mutationFn: addCustomer });

  const updateCustomerMutation = useMutation({ mutationFn: updateCustomer });

  const handleFieldValueChange = (
    field: (typeof CUSTOMER_FIELDS)[number]['field'],
    value: string | undefined,
  ) => {
    if (value === undefined) {
      setCustomer((prevCustomer) => {
        const newCustomer = { ...prevCustomer };
        delete newCustomer[field];
        return newCustomer;
      });
      return;
    }

    setCustomer((prevCustomer) => {
      const newCustomer = { ...prevCustomer };
      newCustomer[field] = value;
      return newCustomer;
    });
  };

  const onSubmit = useMemo(() => {
    if (!validateCustomer(customer)) return undefined;

    return async () => {
      try {
        setSubmitting(true);

        if ('id' in customer) {
          await updateCustomerMutation.mutateAsync(customer);
        } else {
          await addCustomerMutation.mutateAsync(customer);
        }

        await refetchCustomerList();
        onRequestClose();
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        } else {
          console.error(error);
        }
      } finally {
        setSubmitting(false);
      }
    };
  }, [addCustomerMutation, customer, onRequestClose, refetchCustomerList, updateCustomerMutation]);

  return (
    <>
      <Dialog open fullWidth maxWidth="lg">
        <DialogTitle>新增客戶</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {CUSTOMER_FIELDS.map(({ field, name }) => (
              <StringField
                key={field}
                label={name}
                value={customer[field]}
                onChange={(newValue) => handleFieldValueChange(field, newValue)}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose} variant="outlined" disabled={isSubmitting}>
            取消
          </Button>
          <Button variant="contained" color="primary" disabled={!onSubmit || isSubmitting} onClick={onSubmit}>
            {isSubmitting ? <CircularProgress size={24} /> : '儲存'}
          </Button>
        </DialogActions>
      </Dialog>
      {errorMessage && (
        <ErrorMessage message={errorMessage} onRequestClose={() => setErrorMessage(undefined)} />
      )}
    </>
  );
}

export default CustomerEditDialog;
