import { DataGrid } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useSampleListQuery, useSampleTagGroupListQuery, useSampleTagListQuery } from 'src/hooks';
import type { Sample, SampleTag, SampleTagValue } from 'src/models/sample';
import ImagePreview from './ImagePreview';

function SampleTable({
  onSelected,
  filterValues,
}: {
  onSelected?: (sample: Sample | undefined) => void;
  filterValues: string[];
}) {
  const { sampleTagGroupList, isSampleTagGroupListLoading } = useSampleTagGroupListQuery();
  const { sampleTagList, isSampleTagListLoading } = useSampleTagListQuery();
  const { sampleList, isSampleListLoading } = useSampleListQuery();

  const sampleTagIdMap = useMemo(
    () =>
      sampleTagList.reduce<Record<number, SampleTag>>(
        (result, sampleTag) => ({ ...result, [sampleTag.id]: sampleTag }),
        {},
      ),
    [sampleTagList],
  );

  const columns = sampleTagGroupList.flatMap(({ tagFieldIds }) =>
    tagFieldIds.flatMap((sampleTagId) => {
      const sampleTag = sampleTagIdMap[sampleTagId];
      if (!sampleTag) return [];
      return {
        field: sampleTag.id.toString(),
        width: 150,
        headerName: sampleTag.name,
        sampleTagId,
        sampleTagType: sampleTag.type,
        renderCell(params: { row: Record<string, SampleTagValue | undefined> }) {
          const tagValue = params.row[sampleTag.id.toString()];
          if (sampleTag.type === 'boolean') {
            return tagValue === sampleTag.name ? 'V' : undefined;
          }
          if (sampleTag.type === 'image' && typeof tagValue === 'string') {
            return <ImagePreview imageSrc={tagValue as string} />;
          }
          return tagValue;
        },
      };
    }),
  );

  const columnGroupingModel = sampleTagGroupList.map(({ name, tagFieldIds }) => ({
    groupId: name,
    children: tagFieldIds.map((sampleTagId) => ({
      field: sampleTagId.toString(),
    })),
  }));

  const rows = sampleList.map((sample, index) => {
    return columns.reduce(
      (result, column) => {
        if (column.sampleTagType === 'boolean') {
          result[column.field] = sample.tags[column.sampleTagId] ? column.headerName : '';
        } else {
          result[column.field] = sample.tags[column.sampleTagId];
        }

        return result;
      },
      { id: index } as Record<string, SampleTagValue | undefined>,
    );
  });

  return (
    <DataGrid
      loading={isSampleTagGroupListLoading || isSampleTagListLoading || isSampleListLoading}
      rows={rows}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={(params) => {
        const rowIndex = params.row.id;
        onSelected?.(sampleList[rowIndex]);
      }}
      disableColumnFilter
      disableColumnSelector
      filterModel={{ items: [], quickFilterValues: filterValues }}
      sx={{ minHeight: 300 }}
      rowHeight={30}
    />
  );
}

export default SampleTable;
