import { Tabs, Tab, Box } from '@mui/material';
import type { PropsWithChildren, SyntheticEvent } from 'react';

type TabPanelProps = PropsWithChildren<{ name: string }>;

function CustomTabPanel(props: TabPanelProps) {
  const { children, name } = props;

  return (
    <div role="tabpanel" aria-labelledby={`tab-${name}`}>
      {children}
    </div>
  );
}

function a11yProps(index: number) {
  return { 'aria-controls': `tabpanel-${index}` };
}

function CustomTabs({
  tabs,
  currentTab: currentIndex,
  onTabChange,
}: {
  tabs: TabPanelProps[];
  currentTab: number;
  onTabChange: (newTab: number) => void;
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentIndex}
          onChange={(_: SyntheticEvent, newIndex: number) => onTabChange?.(newIndex)}
          aria-label="tabs"
        >
          {tabs.map(({ name }, index) => (
            <Tab key={`tab-${name}`} label={name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.flatMap(({ name, children }, index) =>
        index === currentIndex ? (
          <CustomTabPanel key={`tabpanel-${name}`} name={name}>
            {children}
          </CustomTabPanel>
        ) : (
          []
        ),
      )}
    </Box>
  );
}

export default CustomTabs;
