import type { BusinessQuoteDetail } from 'src/models/businessQuote';

export const BUSINESS_QUOTE_FIELD_TO_NAME = {
  createDate: '日期',
  customerCode: '客戶代號',
  customerName: '客戶名稱',
  creator: '建立者',
  signStatus: '簽核狀態',
} as const;

export const BUSINESS_QUOTE_MODEL_FIELD_TO_NAME = {
  currency: '幣別',
  exchangeRate: '匯率',
  customerModel: '客人型號',
  factoryQuoteSum: '基本價',
  cost: '成本',
  profit: '外貿利潤',
  orderNumPc: '訂單數量(PC)',
  orderNumDz: '訂單數量(DZ)',
  fobPc: 'FOB(PC)',
  fobDz: 'FOB(DZ)',
  costSum: '總成本',
  fobSum: '總FOB',
  profitInPercentage: '利潤(%)',
} as const;

export const BUSINESS_QUOTE_CURRENCY_OPTIONS: { value: BusinessQuoteDetail['currency']; label: string }[] = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'NTD', label: 'NTD' },
] as const;
