import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';

function DeleteButton({ onClick }: { onClick: () => void }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>刪除</Button>
      <Dialog open={open}>
        <DialogContent>確定要刪除嗎?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="outlined">
            取消
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onClick();
              setOpen(false);
            }}
          >
            刪除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteButton;
